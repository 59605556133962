<template>
	<div id="app">
		<b-container class="bv-example-row">
			<header class="my-5">
				<b-row align-h="center">
					<b-col class="rent" md="auto">
						<b-img :src="require('@/assets/image_01.png')" fluid alt="租售"></b-img>
					</b-col>
					<b-col>
						<b-img class="c-mb-2" :src="require('@/assets/image_02.png')" fluid alt="注目新焦點；專利設計、新型看板"></b-img>
						<b-img class="c-mb-2" :src="require('@/assets/image_03.png')" fluid alt="雲龍架"></b-img>
						<b-img :src="require('@/assets/image_04.png')" fluid alt="傳達租售訊息、提升專業形象"></b-img>
					</b-col>
				</b-row>
			</header>
			<b-row class="mb-5" no-gutters>
				<b-col sm="12" md="4">
					<b-img :src="require('@/assets/image_05.png')" fluid alt="雲龍架示意圖"></b-img>
				</b-col>
				<b-col sm="12" md="4">
					<b-img :src="require('@/assets/image_06.png')" fluid alt="雲龍架示意圖"></b-img>
				</b-col>
				<b-col sm="12" md="4">
					<b-img :src="require('@/assets/image_07.png')" fluid alt="雲龍架示意圖"></b-img>
				</b-col>
			</b-row>
			<b-row class="mb-5" no-gutters>
				<b-col cols="auto">
					<b-img :src="require('@/assets/image_08.png')" fluid alt="雲龍架示意圖"></b-img>
				</b-col>
				<b-col>
					<b-img :src="require('@/assets/image_09.png')" fluid alt="雲龍架示意圖"></b-img>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="auto">
					<b-img class="mb-3" :src="require('@/assets/image_10.png')" fluid alt="雲龍架"></b-img>
				</b-col>
				<b-col>
					<b-img class="mb-3" :src="require('@/assets/image_11.png')" fluid alt="組合式圍籬看板"></b-img>
				</b-col>
			</b-row>
			<hr class="c-hr">
			<b-img class="mb-5" :src="require('@/assets/image_12.png')" fluid alt="施工快速方便、適應各種地形"></b-img>

		</b-container>
		<div class="bg-primary py-5">
			<b-container>
				<b-row align-h="center" class="mb-5">
					<b-col cols="auto">
						<b-row align-h="center" class="mb-5">
							<b-col cols="3">
								<b-img :src="require('@/assets/image_13.png')" fluid alt="雲龍架示意圖"></b-img>
							</b-col>
							<b-col cols="9">
								<b-img :src="require('@/assets/image_14.png')" fluid alt="雲龍架示意圖"></b-img>
							</b-col>
						</b-row>
					</b-col>

					<b-col cols="auto">
						<b-img :src="require('@/assets/image_15.png')" fluid alt="雲龍架示意圖"></b-img>
					</b-col>
				</b-row>
				<b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/embed/sb5ZC36ovpg" allowfullscreen>
				</b-embed>
			</b-container>
		</div>
		<div class="bg-white pt-5">
			<b-container>
				<b-row class="mb-3">
					<b-col sm="12" md="4">
						<b-img class="mb-3" :src="require('@/assets/title_01.png')" fluid alt="雲龍架示意圖"></b-img>
						<b-img class="mb-3" :src="require('@/assets/image_16.png')" fluid alt="雲龍架示意圖"></b-img>
						<ul class="list-unstyled">
							<li>適用各種地形</li>
							<li>組建：帆布＋鐵架＋基塊</li>
							<li>帆布尺：240*360cm</li>
							<li>照明：200w投射燈＋太陽能板</li>
						</ul>
						<ul class="list-unstyled">
							<li>單價：<strong class="price">7200+投射燈2000</strong> </li>
							<li>租期：三個月</li>
							<li>內容：帆布製作＋安裝架設＋拆除回收</li>
							<li>延期：三個月 2000 照明 2000</li>
							<li>原有帆布重複使用 -1200（限本公司製作）</li>
						</ul>
						<hr>
					</b-col>
					<b-col sm="12" md="4">
						<b-img class="mb-3" :src="require('@/assets/title_02.png')" fluid alt="雲龍架示意圖"></b-img>
						<b-img class="mb-3" :src="require('@/assets/image_17.png')" fluid alt="雲龍架示意圖"></b-img>
						<ul class="list-unstyled">
							<li>適用各種地形</li>
							<li>組建：帆布＋鐵架＋基塊</li>
							<li>帆布尺：240*360cm</li>
							<li>照明：200w投射燈＋太陽能板</li>
						</ul>
						<ul class="list-unstyled">
							<li>單價：<strong class="price">7200+投射燈2000</strong> </li>
							<li>租期：三個月</li>
							<li>內容：帆布製作＋安裝架設＋拆除回收</li>
							<li>延期：三個月 2000 照明 2000</li>
							<li>原有帆布重複使用 -1200（限本公司製作）</li>
						</ul>
						<hr>
					</b-col>
					<b-col sm="12" md="4">
						<b-img class="mb-3" :src="require('@/assets/title_03.png')" fluid alt="雲龍架示意圖"></b-img>
						<b-img class="mb-3" :src="require('@/assets/image_18.png')" fluid alt="雲龍架示意圖"></b-img>
						<ul class="list-unstyled">
							<li>適用各種地形</li>
							<li>組建：帆布＋鐵架</li>
							<li>帆布尺：240*360cm</li>
							<li>照明：200w投射燈＋太陽能板</li>
						</ul>
						<ul class="list-unstyled">
							<li>單價：<strong class="price">4300+投射燈2000</strong> </li>
							<li>租期：三個月</li>
							<li>內容：帆布製作＋安裝架設＋拆除回收</li>
							<li>延期：三個月 1000 照明 2000</li>
							<li>原有帆布重複使用 -1200（限本公司製作）</li>
						</ul>
						<hr>
					</b-col>
				</b-row>
				<b-row class="mb-3">
					<b-col sm="12" md="8">
						<b-img class="mb-3" :src="require('@/assets/title_04.png')" fluid alt="雲龍架示意圖"></b-img>
						<b-img class="mb-3" :src="require('@/assets/image_19.png')" fluid alt="雲龍架示意圖"></b-img>
						<ul class="list-unstyled">
							<li>適用各種地形</li>
							<li>組建：帆布＋鐵架＋基塊＋照明</li>
							<li>帆布尺：大型尺寸訂製</li>
							<li>免費丈量評估</li>
							<li>洽詢專線：04-2372-5034</li>
						</ul>
						<ul class="list-unstyled">
							<li>單價：個案評估</li>
							<li>租期：不限</li>
							<li>內容：帆布製作＋安裝架設＋拆除回收</li>
						</ul>
						<hr>
					</b-col>
					<b-col sm="12" md="4">
						<b-img class="mb-3" :src="require('@/assets/title_05.png')" fluid alt="雲龍架示意圖"></b-img>
						<b-img class="mb-3" :src="require('@/assets/image_20.png')" fluid alt="雲龍架示意圖"></b-img>
						<p class="pedestal">
							<strong>獨創設計、多功能運用</strong><br />
							燈柱／招牌<br />
							指標／棚架<br />
							適用各種基座
						</p>
						<ul class="list-unstyled">
							<li>單價：<strong class="price">4500</strong> </li>
						</ul>
						<hr>
					</b-col>
				</b-row>

				<div class="connection">
					<b-img :src="require('@/assets/image_21.png')" fluid alt="雲龍架示意圖"></b-img>
				</div>
				<b-img class="mb-5" :src="require('@/assets/image_22.png')" fluid alt="雲龍架示意圖"></b-img>
				<b-img :src="require('@/assets/image_23.png')" fluid alt="雲龍架示意圖"></b-img>
				<b-img :src="require('@/assets/image_24.png')" fluid alt="雲龍架示意圖"></b-img>
			</b-container>
		</div>
		<b-container class="py-5">
			<b-row align-h="center" class="mb-5">
				<b-col cols="auto">
					<b-img class="mb-3" :src="require('@/assets/image_25.png')" fluid alt="雲龍架示意圖"></b-img>
				</b-col>
				<b-col cols="auto">
					<b-img class="mb-3" :src="require('@/assets/image_26.png')" fluid alt="雲龍架示意圖"></b-img>
				</b-col>
			</b-row>
			<b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/embed/MRAYFOjY88s" allowfullscreen>
			</b-embed>
			<b-row align-h="center" class="mt-5">
				<b-col cols="auto">
					<b-img class="mb-3" :src="require('@/assets/image_27.png')" fluid alt="雲龍架示意圖"></b-img>
				</b-col>
				<b-col cols="auto">
					<b-img class="mb-3" :src="require('@/assets/image_28.png')" fluid alt="雲龍架示意圖"></b-img>
				</b-col>
				<b-col cols="auto">
					<b-img class="mb-3" :src="require('@/assets/image_29.png')" fluid alt="雲龍架示意圖"></b-img>
				</b-col>
				<b-col cols="auto">
					<b-img class="mb-3" :src="require('@/assets/image_30.png')" fluid alt="雲龍架示意圖"></b-img>
				</b-col>
				<b-col cols="auto">
					<b-img class="mb-3" :src="require('@/assets/image_31.png')" fluid alt="雲龍架示意圖"></b-img>
				</b-col>
			</b-row>
			<b-img class="mb-3" :src="require('@/assets/orderProcess.png')" fluid alt="雲龍架示意圖"></b-img>
			<b-button size="lg" block variant="primary" href="http://u-long-frame.shiueyi.com.tw/ulongOrder.xlsx" download>訂購單下載</b-button>
		</b-container>
	</div>
</template>

<script>
	export default {
		name: 'ULONG',
		methods: {
		}
	}
</script>